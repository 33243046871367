<template>
  <st-title :title="$t('studio.group.notice.title')" />
  <st-box>
    <st-table
      :data="noticeList?.contents ?? []"
      :columns="columns"
      tableClass="[&>tbody>tr:hover>td:nth-child(2)>a>span]:underline"
      :paging="{ total: noticeList?.totalElements ?? 0, pageSize: noticeList?.size, show: true }"
      @pageChange="onChangePage"
    >
      <template #row="{ row: item }">
        <st-tr :hasLink="true">
          <st-td aLeft>
            <div class="flex items-center gap-8">
              <s-icon
                v-if="item.isFixed"
                icon="ic-v2-community-pin-fill"
                size="xl"
                class="text-brand-primary flex shrink-0"
              />
              <span class="font-medium text-md" :class="{ 'text-brand-primary': item.isFixed }">{{
                item.label
              }}</span>
            </div>
          </st-td>

          <st-td aLeft class="flex gap-4 justify-start">
            <a
              href=""
              class="inline-flex gap-8 items-center"
              @click.prevent="navigateToNoticeDetail(item.noticeId.toString())"
            >
              <st-tooltip-text-over position="bottom-start" :content="item.title">
                <span class="w-auto line-clamp-1 font-medium break-all text-md pr-[0.1rem]">{{
                  item.title
                }}</span>
              </st-tooltip-text-over>
            </a>
            <s-icon
              v-if="item.attachedFile"
              icon="ic-v2-community-attach-file-line"
              size="xl"
              class="text-on-surface-elevation-3 flex shrink-0"
            />
            <s-new-badge
              v-if="isNewNotice(item.createDt)"
              variant="dot"
              class="shrink-0 !bg-orange500"
            />
          </st-td>
          <st-td aLeft textMedium :tdValue="getDateTimeByLocale(item.createDt)" />
        </st-tr>
      </template>
      <template #empty>
        <st-tr>
          <st-td colspan="3" aCenter class="h-200">{{ $t('studio.notice.none_guide') }}</st-td>
        </st-tr>
      </template>
    </st-table>
  </st-box>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import { NOTICE_PAGE_URL, PUBLIC_NOTICE_PAGE_URL } from '@/constants/url.const';
import { useNoticeStore } from '@/stores/notice.store';
import type { Column } from '@/types/table.type';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { isNewNotice } from '@/utils/notice.util';

const props = defineProps<{
  isPublic?: boolean;
}>();
const { isPublic } = toRefs(props);

const { t } = useI18n();

const noticeStore = useNoticeStore();
const { fetchNoticeList, fetchLatestNotice } = noticeStore;
const { noticeList } = storeToRefs(noticeStore);

const navigateToNoticeDetail = (id: string) => {
  const path = isPublic.value ? `${PUBLIC_NOTICE_PAGE_URL}/${id}` : `${NOTICE_PAGE_URL}/${id}`;

  redirectTo(path);
};

const columns = ref<Column[]>([
  {
    title: t('studio.group.notice.list_header1'),
    width: '16.8rem',
    titleTooltip: ''
  },
  {
    title: t('studio.group.notice.list_header2'),
    width: 'auto',
    titleTooltip: ''
  },
  {
    title: t('studio.group.notice.list_header3'),
    width: '16.8rem',
    titleTooltip: ''
  }
]);

const getNoticeList = (page: number) => {
  const params = {
    size: 50,
    page
  };

  fetchNoticeList(params);
  fetchLatestNotice();
};
const onChangePage = (page: number) => {
  if (page === noticeList.value?.page) {
    return;
  }
  getNoticeList(page);
};
const init = () => {
  getNoticeList(1);
};
init();
</script>
